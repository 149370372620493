<template>
  <four-panel
    :topLeftPanelTitle="topLeftPanelTitle"
    :topRightPanelTitle="topRightPanelTitle"
    :bottomLeftPanelTitle="bottomLeftPanelTitle"
    :bottomRightPanelTitle="bottomRightPanelTitle"
    :topLeftPanelWidth="'775'"
    :topRightPanelWidth="'612'"
    :bottomLeftPanelWidth="'775'"
    :bottomRightPanelWidth="'612'"
  >
    <!---------------------------- 사용자 ---------------------------->
    <template #topLeftPanel>
      <!------------ 목록 ------------>
      <table-list-component
        :tableHeight="'231'"
        :headers="user.headers"
        :items="user.tableItems"
        :nowPerPage="user.nowPerPage"
        :totalRecords="user.totalRecords"
        :totalPages="user.totalPages"
        :searchOpts="user.searchOpts"
        :customSlotInfo="user.customSlotInfo"
        :loading="user.loading"
        @getDataFromApi="searchUserList"
      >
        <!---- 검색 옵션 ---->
        <template #searchOptions>
          <label class="sort-label"
            >· {{ $t('phr.annymty.topLeftPanel.search-condition1') }}</label
          >
          <select
            v-model="user.searchOpts.srvcId"
            class="sort-input-select"
            style="width: 145px"
          >
            <option value="">{{ $t('common.all') }}</option>
            <option
              v-for="(item, index) in srvcList"
              :key="index"
              :value="item.srvcId"
            >
              {{ item.srvcId }}
            </option>
          </select>
          <label class="sort-label"
            >· {{ $t('phr.annymty.topLeftPanel.search-condition2') }}</label
          >
          <vc-date-picker
            mode="date"
            v-model="user.searchOpts.searchDate"
            :model-config="modelConfig"
            :columns="$screens({ default: 2, lg: 2 })"
            color="orange"
            is-range
          >
            <template v-slot="{ inputEvents }">
              <label class="input-calendar-style">
                <input
                  v-model="user_dateString"
                  v-on="inputEvents.start"
                  type="text"
                  class="input-calendar"
                  readonly
                />
                <button
                  class="bt-calendar-clear"
                  @click="clearCalendar('user')"
                />
                <!-- <span class="i-calendar"></span> -->
              </label>
            </template>
          </vc-date-picker>
        </template>
        <!---- subHeaderItem ---->
        <template #subHeaderItem>
          <span style="color: black">
            K-Anonymity:
            <input
              v-model="user.kAnonymity"
              type="number"
              class="sort-input-text"
              style="width:50px"
              @keydown="inputNumberCheck"
            />
          </span>
          <input
            type="button"
            :value="btnDeIdentification"
            class="bt-line24 cr-orange"
            @click="anonymizeUser"
            :disabled="annymty.USER_ANNYMTY.progress"
          />
        </template>
        <!---- customSlot ---->
        <template
          v-for="(slot, index) in user.customSlotInfo"
          v-slot:[`${slot.name}_custom`]="{ item }"
        >
          <td
            class="text-center td-ellipsis"
            :title="item[slot.name]"
            :key="index"
          >
            {{
              (slot.name == 'userBrthdy'
                ? getBirthDay(item[slot.name])
                : item[slot.name]) || '-'
            }}
          </td>
        </template>
      </table-list-component>
    </template>
    <template #topRightPanel>
      <!------------ 사용자 비식별화 결과 ------------>
      <div class="panel-body b-t1" style="height:305px; overflow:hidden;">
        <div style="padding: 4px 7px;">
          <!-- 비식별화 결과 -->
          <textarea
            v-model="annymty.USER_ANNYMTY.arxResult"
            class="input-24 textarea-105"
            readonly
          />
          <p class="b-t1"></p>
          <!-- 비식별화 csv -->
          <textarea
            id="user-csv"
            v-model="userCsvString"
            class="input-24 textarea-180"
            readonly
          />
        </div>
      </div>
      <div class="b-t1">
        <p class="f-right" style="margin-bottom: 4px;">
          <!-- thread 진행중인경우 -->
          <b class="mr-5">{{ getThreadResult('USER_ANNYMTY') }}</b>
          <input
            type="button"
            style="margin: 4px 4px 0 0 ;"
            :value="btnCancel"
            class="bt-line24 cr-orange"
            @click="stopAnonymize(PROP_USER_ANNYMTY)"
            :disabled="annymty.USER_ANNYMTY.stopFlag"
          />
        </p>
      </div>
      <!-- overlay -->
      <v-overlay absolute="absolute" :value="annymty.USER_ANNYMTY.progress">
        <v-progress-circular
          indeterminate
          size="50"
          color="deep-orange lighten-2"
        />
      </v-overlay>
    </template>
    <!---------------------------- 사용자 측정내역 ---------------------------->
    <template #bottomLeftPanel>
      <!------------ 목록 ------------>
      <table-list-component
        :tableHeight="'231'"
        :headers="mesure.headers"
        :items="mesure.tableItems"
        :nowPerPage="mesure.nowPerPage"
        :totalRecords="mesure.totalRecords"
        :totalPages="mesure.totalPages"
        :searchOpts="mesure.searchOpts"
        :customSlotInfo="mesure.customSlotInfo"
        :loading="mesure.loading"
        @getDataFromApi="searchMsreList"
      >
        <!---- 검색 옵션 ---->
        <template #searchOptions>
          <label class="sort-label"
            >· {{ $t('phr.annymty.bottomLeftPanel.search-condition1') }}</label
          >
          <select
            v-model="mesure.searchOpts.srvcId"
            class="sort-input-select"
            style="width: 145px"
          >
            <option value="">{{ $t('common.all') }}</option>
            <option
              v-for="(item, index) in srvcList"
              :key="index"
              :value="item.srvcId"
            >
              {{ item.srvcId }}
            </option>
          </select>
          <label class="sort-label"
            >· {{ $t('phr.annymty.bottomLeftPanel.search-condition2') }}</label
          >
          <vc-date-picker
            mode="date"
            v-model="mesure.searchOpts.searchDate"
            :model-config="modelConfig"
            :columns="$screens({ default: 2, lg: 2 })"
            color="orange"
            is-range
          >
            <template v-slot="{ inputEvents }">
              <label class="input-calendar-style">
                <input
                  v-model="mesure_dateString"
                  v-on="inputEvents.start"
                  type="text"
                  class="input-calendar"
                  readonly
                />
                <button
                  class="bt-calendar-clear"
                  @click="clearCalendar('mesure')"
                />
                <!-- <span class="i-calendar"></span> -->
              </label>
            </template>
          </vc-date-picker>
        </template>
        <!---- subHeaderItem ---->
        <template #subHeaderItem>
          <input
            type="button"
            :value="btnDeIdentification"
            class="bt-line24 cr-orange"
            :disabled="annymty.USER_MESURE.progress"
            @click="anonymizeMesure"
          />
        </template>
        <!---- customSlot ---->
        <template
          v-for="(slot, index) in mesure.customSlotInfo"
          v-slot:[`${slot.name}_custom`]="{ item }"
        >
          <td
            class="text-center td-ellipsis"
            :title="item[slot.name]"
            :key="index"
          >
            {{ item[slot.name] || '-' }}
          </td>
        </template>
      </table-list-component>
    </template>
    <template #bottomRightPanel>
      <!------------ 측정 내역 비식별화 결과 ------------>
      <div class="panel-body b-t1" style="height:305px; overflow:hidden;">
        <div style="padding: 4px 7px;">
          <!-- 비식별화 결과 -->
          <textarea
            v-model="annymty.USER_MESURE.arxResult"
            class="input-24 textarea-105"
            readonly
          />
          <!-- 비식별화 csv -->
          <p class="b-t1"></p>
          <textarea
            id="mesure-csv"
            v-model="mesureCsvString"
            class="input-24 textarea-180"
            readonly
          />
        </div>
      </div>
      <div class="b-t1">
        <p class="f-right" style="margin-bottom: 4px;">
          <!-- thread 진행중인경우 -->
          <b class="mr-5">{{ getThreadResult('USER_MESURE') }}</b>
          <input
            type="button"
            style="margin: 4px 4px 0 0 ;"
            :value="btnCancel"
            class="bt-line24 cr-orange"
            @click="stopAnonymize(PROP_USER_MESURE)"
            :disabled="annymty.USER_MESURE.stopFlag"
          />
        </p>
      </div>
      <!-- overlay -->
      <v-overlay absolute="absolute" :value="annymty.USER_MESURE.progress">
        <v-progress-circular
          indeterminate
          size="50"
          color="deep-orange lighten-2"
        />
      </v-overlay>
    </template>
  </four-panel>
</template>

<script>
import FourPanel from '@/layouts/FourPanel.vue';
import TableListComponent from '@/components/TableListComponent.vue';
import CommonMixins from '@/mixins/CommonMixins';
import { getUserList, getMesureList } from '@/api/phrApi';
import { ANNYMTY_THREAD_RESULT } from '@/store/properties';

export default {
  components: { FourPanel, TableListComponent },
  mixins: [CommonMixins],
  data() {
    return {
      // --- 공통: 검색용 --- //
      srvcList: this.$store.state.common.srvcList,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      // --- 사용자 테이블 --- //
      user: {
        loading: false,
        headers: [],
        // custom Slot
        customSlotInfo: [
          { name: 'userNm', slotName: 'item.userNm' },
          { name: 'srvcId', slotName: 'item.srvcId' },
          { name: 'userBrthdy', slotName: 'item.userBrthdy' },
          { name: 'userSexdstn', slotName: 'item.userSexdstn' },
          { name: 'userAdres', slotName: 'item.userAdres' },
          { name: 'srbdeDt', slotName: 'item.srbdeDt' },
          { name: 'updtDt', slotName: 'item.updtDt' },
        ],
        // 검색 정보
        searchOpts: {
          srvcId: '',
          searchDate: {
            start: '',
            end: '',
          },
        },
        // row
        totalRecords: 0,
        totalPages: 0,
        tableItems: [],
        nowPerPage: 30,
        reloadToggle: true,
        // 비식별화
        kAnonymity: 5,
      },
      // --- 측정 내역 테이블 --- //
      mesure: {
        loading: false,
        headers: [],
        // custom Slot
        customSlotInfo: [
          { name: 'resId', slotName: 'item.resId' },
          { name: 'srvcId', slotName: 'item.srvcId' },
          { name: 'mesureNo', slotName: 'item.mesureNo' },
          { name: 'mesureCodeNm', slotName: 'item.mesureCodeNm' },
          { name: 'mesureDt', slotName: 'item.mesureDt' },
          { name: 'mesureValue', slotName: 'item.mesureValue' },
          { name: 'mesureUnitNm', slotName: 'item.mesureUnitNm' },
          { name: 'creatDt', slotName: 'item.creatDt' },
        ],
        searchOpts: {
          srvcId: '',
          searchDate: {
            start: '',
            end: '',
          },
        },
        // row
        totalRecords: 0,
        totalPages: 0,
        tableItems: [],
        nowPerPage: 30,
        reloadToggle: true,
      },
      // --- 비식별화 관련 --- //
      PROP_USER_ANNYMTY: this.$store.state.phr.annymty.PROP_USER_ANNYMTY,
      PROP_USER_MESURE: this.$store.state.phr.annymty.PROP_USER_MESURE,
    };
  },
  computed: {
    // date range
    user_dateString() {
      if (
        !Object.keys(this.user.searchOpts.searchDate).length ||
        this.user.searchOpts.searchDate.start == ''
      ) {
        return '';
      } else {
        return `${this.user.searchOpts.searchDate.start} ~ ${this.user.searchOpts.searchDate.end}`;
      }
    },
    mesure_dateString() {
      if (
        !Object.keys(this.mesure.searchOpts.searchDate).length ||
        this.mesure.searchOpts.searchDate.start == ''
      ) {
        return '';
      } else {
        return `${this.mesure.searchOpts.searchDate.start} ~ ${this.mesure.searchOpts.searchDate.end}`;
      }
    },
    // annymty state
    annymty() {
      return this.$store.state.phr.annymty;
    },
    // csv csvTextArea 출력 getter
    userCsvString() {
      return this.$store.getters['phr/getUserCsvString'];
    },
    mesureCsvString() {
      return this.$store.getters['phr/getMesureCsvString'];
    },
    topLeftPanelTitle() {
      return this.$i18n.t('phr.annymty.topLeftPanel.title');
    },
    topRightPanelTitle() {
      return this.$i18n.t('phr.annymty.topRightPanel.title');
    },
    bottomLeftPanelTitle() {
      return this.$i18n.t('phr.annymty.bottomLeftPanel.title');
    },
    bottomRightPanelTitle() {
      return this.$i18n.t('phr.annymty.bottomRightPanel.title');
    },
    userHeaders() {
      return [
        {
          text: this.$i18n.t('phr.annymty.topLeftPanel.table-column1'),
          value: 'resId',
          width: '12%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.topLeftPanel.table-column2'),
          value: 'userNm',
          width: '11%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.topLeftPanel.table-column3'),
          value: 'srvcId',
          width: '16%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.topLeftPanel.table-column4'),
          value: 'userBrthdy',
          width: '12%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.topLeftPanel.table-column5'),
          value: 'userSexdstn',
          width: '8%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.topLeftPanel.table-column6'),
          value: 'userAdres',
          width: '9%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.topLeftPanel.table-column7'),
          value: 'srbdeDt',
          width: '16%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.topLeftPanel.table-column8'),
          value: 'updtDt',
          width: '16%',
          sortable: false,
        },
      ];
    },
    mesureHeaders() {
      return [
        {
          text: this.$i18n.t('phr.annymty.bottomLeftPanel.table-column1'),
          value: 'resId',
          width: '12%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.bottomLeftPanel.table-column2'),
          value: 'srvcId',
          width: '20%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.bottomLeftPanel.table-column3'),
          value: 'mesureNo',
          width: '7%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.bottomLeftPanel.table-column4'),
          value: 'mesureCodeNm',
          width: '13%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.bottomLeftPanel.table-column5'),
          value: 'mesureDt',
          width: '17%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.bottomLeftPanel.table-column6'),
          value: 'mesureValue',
          width: '8%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.bottomLeftPanel.table-column7'),
          value: 'mesureUnitNm',
          width: '7%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.annymty.bottomLeftPanel.table-column8'),
          value: 'creatDt',
          width: '16%',
          sortable: false,
        },
      ];
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    btnDeIdentification() {
      return this.$i18n.t('common.btn.deidentification');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    /**
     * csvTextArea 업데이트 될 때마다, 스크롤 하단
     * fileNm null인 경우, 작업이 끝났으므로 스크롤 상단
     */
    userCsvString() {
      const textarea = document.getElementById('user-csv');
      const filePath = this.$store.state.phr.annymty.USER_ANNYMTY.filePath;
      const progress = this.$store.state.phr.annymty.USER_ANNYMTY.progress;
      if (progress && filePath) {
        textarea.scrollTop = textarea.scrollHeight;
      } else {
        textarea.scrollTop = 0;
      }
    },
    mesureCsvString() {
      const textarea = document.getElementById('mesure-csv');
      const filePath = this.$store.state.phr.annymty.USER_MESURE.filePath;
      const progress = this.$store.state.phr.annymty.USER_MESURE.progress;
      if (progress && filePath) {
        textarea.scrollTop = textarea.scrollHeight;
      } else {
        textarea.scrollTop = 0;
      }
    },
    'annymty.USER_ANNYMTY.progress'() {
      if (!this.$store.state.phr.annymty.USER_ANNYMTY.progress) {
        document.getElementById('user-csv').scrollTop = 0;
      }
    },
    'annymty.USER_MESURE.progress'() {
      if (!this.$store.state.phr.annymty.USER_MESURE.progress) {
        document.getElementById('mesure-csv').scrollTop = 0;
      }
    },
    lang() {
      this.user.headers = this.userHeaders;
      this.mesure.headers = this.mesureHeaders;
    },
  },
  methods: {
    // 사용자 목록 조회
    async searchUserList(searchParams) {
      this.user.loading = true;
      const reqObj = {
        rows: searchParams.rows,
        page: searchParams.page,
        sord: searchParams.sord,
        sidx: searchParams.sidx,
        srvcId: searchParams.srvcId,
        startDateString: searchParams.searchDate.start,
        endDateString: searchParams.searchDate.end,
      };
      await getUserList(reqObj).then(res => {
        const { rows, totalRecords, totalPages } = res.data;
        this.user.tableItems = rows;
        this.user.totalRecords = totalRecords;
        this.user.totalPages = totalPages;
        this.user.loading = false;
      });
    },
    // 사용자 측정 목록 조회
    async searchMsreList(searchParams) {
      this.mesure.loading = true;
      const reqObj = {
        rows: searchParams.rows,
        page: searchParams.page,
        sord: searchParams.sord,
        sidx: searchParams.sidx,
        srvcId: searchParams.srvcId,
        creatDtStartDateString: searchParams.searchDate.start,
        creatDtEndDateString: searchParams.searchDate.end,
      };
      await getMesureList(reqObj).then(res => {
        const { rows, totalRecords, totalPages } = res.data;
        this.mesure.tableItems = rows;
        this.mesure.totalRecords = totalRecords;
        this.mesure.totalPages = totalPages;
        this.mesure.loading = false;
      });
    },
    // 비식별화
    async anonymizeUser() {
      // 1.validate
      const { totalRecords, kAnonymity, searchOpts } = this.user;

      // 선택된 사용자 목록이 없는 경우
      if (!totalRecords) {
        const target = this.getPostWord(
          this.$i18n.t('phr.annymty.target-userList'),
          '이',
          '가',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-empty', { target }),
        );
        return;
      }

      // kAnonymity 값이 없는 경우
      if (kAnonymity === '' || kAnonymity === null || kAnonymity === 0) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert.required-field', { field: 'K-Anonymity' }),
        );
        this.user.kAnonymity = 5;
        return;
      }

      // kAnonymity의 값이 전체 목록 개수보다 큰 경우
      if (kAnonymity >= totalRecords) {
        this.$dialogs.alert(this.$i18n.t('common.alert.invalid-kAnonymity'));
        return;
      }

      // 2.anonymize전 처리 작업 (startAnoymize 주석 확인)
      await this.startAnonymize(this.PROP_USER_ANNYMTY).then(async () => {
        const filePath = this.$store.state.phr.annymty.USER_ANNYMTY.filePath;
        const folderNm_fileNm = filePath.split('/');
        const reqObj = {
          kAnonymity,
          startDateString: searchOpts.searchDate.start,
          endDateString: searchOpts.searchDate.end,
          srvcId: searchOpts.srvcId,
          folderNm: folderNm_fileNm[0],
          fileNm: folderNm_fileNm[1],
        };

        // 3. 비식별화
        await this.$store
          .dispatch('phr/FETCH_ANONIMIZE', {
            type: this.PROP_USER_ANNYMTY,
            reqObj,
          })
          .finally(() => {
            // 4. 비식별화 완료 처리
            this.clearAnonymize(this.PROP_USER_ANNYMTY);
          });
      });
    },
    // 측정 내역 비식별화
    async anonymizeMesure() {
      // 1.validate
      const { totalRecords, searchOpts } = this.mesure;
      if (!totalRecords) {
        const target = this.getPostWord(
          this.$i18n.t('phr.annymty.target-measureList'),
          '이',
          '가',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-empty', { target }),
        );
        return;
      }

      // 2.anonymize전 처리 작업 (startAnoymize 주석 확인)
      await this.startAnonymize(this.PROP_USER_MESURE).then(async () => {
        const filePath = this.$store.state.phr.annymty.USER_MESURE.filePath;
        const folderNm_fileNm = filePath.split('/');
        const reqObj = {
          mesureDtStartDateString: searchOpts.searchDate.start,
          mesureDtEndDateString: searchOpts.searchDate.end,
          srvcId: searchOpts.srvcId,
          folderNm: folderNm_fileNm[0],
          fileNm: folderNm_fileNm[1],
        };

        // 3. 비식별화
        await this.$store
          .dispatch('phr/FETCH_ANONIMIZE', {
            type: this.PROP_USER_MESURE,
            reqObj,
          })
          .finally(() => {
            // 4. 비식별화 완료 처리
            this.clearAnonymize(this.PROP_USER_MESURE);
          });
      });
    },
    // Thread 조회
    async getAnnymtyThread(type) {
      // 1. anonymize 전, 처리 작업 (startAnonymize 주석 확인)
      await this.startAnonymize(type).then(async () => {
        // 2. Thread 조회
        await this.$store.dispatch('phr/FETCH_THREAD', type).then(() => {
          // 3. 비식별화 완료 처리
          this.clearAnonymize(type);
        });
      });
    },
    // 비식별화 중단
    async stopAnonymize(type) {
      // 1. clearInterval 및 result 초기화
      await this.$store.dispatch('phr/STOP_THREAD', type).then(res => {
        this.$dialogs.alert(res.data.message);
        if (res.data.valid) {
          // 중단 성공 시, 진행 여부 관련 state 초기화
          this.clearAnonymize(type);
        }
      });
    },
    /**
     * anonymize전 처리 작업
     * 1. 기존 결과 삭제
     * 2. filePath 설정, progress: true
     * 3. csvReadInterval Start
     */
    async startAnonymize(type) {
      return new Promise(async resolve => {
        // 1
        await this.$store
          .dispatch('phr/FETCH_CLEAR_PROGRESS_RESULT', type)
          .then(async () => {
            // 2
            await this.$store
              .dispatch('phr/FETCH_PROGRESS_FILEPATH', type)
              .then(async () => {
                // 3
                await this.$store
                  .dispatch('phr/FETCH_CSV_LIST', type)
                  .then(() => {
                    resolve();
                  });
              });
          });
      });
    },
    /**
     * 비식별화 종료 처리
     * - CLEAR read Interval
     * - 완료 후, 비식별화 진행 여부 확인 위한 filePath clear
     */
    clearAnonymize(type) {
      this.$store.dispatch('phr/FETCH_CLEAR_PROGRESS_FILEPATH', type);
    },
    // 생일 split 출력
    getBirthDay(userBrthdy) {
      const arr = userBrthdy.split('.');
      return `${arr[2]}-${arr[1]}-${arr[0]}`;
    },
    // 달력 초기화 (전체 일자)
    clearCalendar(type) {
      if (type == 'user') {
        this.user.searchOpts.searchDate = {};
      } else {
        this.mesure.searchOpts.searchDate = {};
      }
    },
    // store에 저장된 threadResult 코드 값에 따라 메시지 출력 (locale 적용)
    getThreadResult(type) {
      if (this.annymty[type].threadResult === ANNYMTY_THREAD_RESULT.FINISH) {
        return this.$i18n.t('phr.annymty.desc-finish');
      }
      if (this.annymty[type].threadResult === ANNYMTY_THREAD_RESULT.PROGRESS) {
        return `${this.$i18n.t('phr.annymty.desc-progress')} ${
          this.annymty[type].filePath
        }`;
      }
      return '';
    },
  },
  created() {
    // 비식별화 Thread 존재 여부 조회
    const { USER_ANNYMTY, USER_MESURE } = this.annymty;
    // 진행중인 비식별화가 존재하는 경우, Thread 조회 및 출력
    if (USER_ANNYMTY.progress) {
      this.getAnnymtyThread(this.PROP_USER_ANNYMTY);
    }
    if (USER_MESURE.progress) {
      this.getAnnymtyThread(this.PROP_USER_MESURE);
    }
    this.user.headers = this.userHeaders;
    this.mesure.headers = this.mesureHeaders;
  },
  destroyed() {
    // 결과 초기화 및 intervalClear
    this.$store.dispatch(
      'phr/FETCH_CLEAR_PROGRESS_RESULT',
      this.PROP_USER_ANNYMTY,
    );
    this.$store.dispatch(
      'phr/FETCH_CLEAR_PROGRESS_RESULT',
      this.PROP_USER_MESURE,
    );
  },
};
</script>

<style scoped>
.input-calendar-style {
  width: 200px;
}
.input-calendar {
  width: 157px;
  background: #ffffff;
  color: black;
}
.textarea-105 {
  width: 100%;
  height: 105px;
  overflow: auto;
  color: black;
}
.textarea-180 {
  width: 100%;
  height: 180px;
  margin: 4px 0;
  overflow: auto;
  color: black;
}
</style>
