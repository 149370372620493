<template>
  <section>
    <four-panel
      :topLeftPanelWidth="fourPanel.topLeftPanelWidth"
      :topRightPanelWidth="fourPanel.topRightPanelWidth"
      :bottomLeftPanelWidth="fourPanel.topLeftPanelWidth"
      :bottomRightPanelWidth="fourPanel.topRightPanelWidth"
      :topLeftPanelTitle="topLeftPanelTitle"
      :topRightPanelTitle="topRightPanelTitle"
      :bottomLeftPanelTitle="bottomLeftPanelTitle"
      :bottomRightPanelTitle="bottomRightPanelTitle"
    >
      <template #topLeftPanel>
        <table-list-component
          ref="tableListComponent"
          :tableHeight="'231'"
          :headers="topLeftTable.topHeaders"
          :items="itemsWithIndex"
          :nowPerPage="topLeftTable.nowPerPage"
          :totalRecords="topLeftTable.totalRecords"
          :totalPages="topLeftTable.totalPages"
          :searchOpts="topLeftTable.srvc"
          :loading="loading"
          @rowClick="topLeftRowClick"
          @getDataFromApi="getPatientItemsList"
        >
          <!-------------- 검색 옵션 -------------->
          <template #searchOptions>
            <label for="search_srvcId" class="sort-label"
              >· {{ $t('phr.fhir.topLeftPanel.search-condition1') }}</label
            >
            <input
              v-model="topLeftTable.srvc.fhirResrc"
              type="text"
              class="sort-input-text"
              maxlength="20"
              style="width:80px;"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label for="search_srvcNm" class="sort-label"
              >· {{ $t('phr.fhir.topLeftPanel.search-condition2') }}</label
            >
            <input
              v-model="topLeftTable.srvc.userId"
              type="text"
              class="sort-input-text"
              maxlength="20"
              style="width:80px;"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label for="search_srvcSttus" class="sort-label"
              >· {{ $t('phr.fhir.topLeftPanel.search-condition3') }}
            </label>
            <input
              v-model="topLeftTable.srvc.userNm"
              type="text"
              class="sort-input-text"
              maxlength="20"
              style="width:80px;"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
          </template>
        </table-list-component>
      </template>
      <template #topRightPanel>
        <!-- overlay -->
        <v-overlay absolute="absolute" :value="topRightOverlay">
          <v-progress-circular
            indeterminate
            size="50"
            color="deep-orange lighten-2"
          ></v-progress-circular>
        </v-overlay>
        <div class="panel-top-sort">
          <div>
            <p class="table-total">
              <span class="tx-total"
                >{{ $t('phr.fhir.topRightPanel.header1') }}:
                <b id="d_id" class="patient-id">{{ patientInfo.patientId }}</b>
              </span>
            </p>
          </div>
          <div style="margin-right: 10px;float: right;">
            <label for="search_fhirResrc" class="sort-label"
              >· {{ $t('phr.fhir.topRightPanel.header2') }}</label
            >
            <select
              id="d_history"
              class="sort-input-select"
              v-model="selectVersionId"
            >
              <option
                v-for="index in fixedVersionId"
                :key="index"
                :value="index"
              >
                {{ index }}</option
              >
            </select>
          </div>
        </div>
        <div class="panel-body" style="height:304px;overflow:hidden;">
          <div class="table-bot-btns2">
            <textarea
              :value="patientInfo.patientDetailText"
              id="d_json"
              name="d_json"
              style="width:100%; height:291px; overflow:auto; resize: none; border: solid; border-width: 1px; border-color: rgb(118, 118, 118);"
            ></textarea>
          </div>
        </div>
      </template>
      <template #bottomLeftPanel>
        <!-- ref="tableListComponent" -->
        <table-list-component
          :headers="bottomLeftTable.bottomHeaders"
          :items="bottomLeftTable.tableItems"
          :tableHeight="'231'"
          :nowPerPage="bottomLeftTable.nowPerPage"
          :searchOpts="bottomLeftTable.rsrc"
          :totalRecords="bottomLeftTable.totalRecords"
          :totalPages="bottomLeftTable.totalPages"
          :loading="RsrcLoading"
          @getDataFromApi="getRsrcList"
          @rowClick="bottomLeftTableRowClick"
        >
          <template #searchOptions>
            <label for="search_fhirResrcType" class="sort-label"
              >· {{ $t('phr.fhir.bottomLeftPanel.search-condition1') }}</label
            >
            <select
              class="sort-input-select"
              style="width:194px;"
              id="search_fhirResrcType"
              v-model="bottomLeftTable.rsrc.resourceType"
              @change="changeResrceType"
            >
              <option value="">{{
                $t('common.select', { target: '리소스' })
              }}</option>
              <option
                v-for="(item, index) in fhir_resources"
                :key="index"
                :value="item.value"
                >{{ item.text }}</option
              >
            </select>
            <label for="search_fhirCont" class="sort-label" maxlength="20"
              >· {{ $t('phr.fhir.bottomLeftPanel.search-condition2') }}</label
            ><input
              type="text"
              v-model="bottomLeftTable.rsrc.searchUrl"
              class="sort-input-text"
              id="search_fhirCont"
              style="width: 300px; margin-left:5px;"
            />
            <input
              type="button"
              id="contentButton_fhir"
              :value="btnEditOptions"
              class="sort-btn-search"
              style="width: 95px; color: #ffffff; margin-left:25px;"
              @click="openSearchEditPopup"
            />
          </template>
        </table-list-component>
      </template>
      <template #bottomRightPanel>
        <!-- overlay -->
        <v-overlay absolute="absolute" :value="bottomRightOverlay">
          <v-progress-circular
            indeterminate
            size="50"
            color="deep-orange lighten-2"
          ></v-progress-circular>
        </v-overlay>
        <div class="panel-top-sort">
          <div>
            <p class="table-total">
              <span class="tx-total"
                >{{ $t('phr.fhir.bottomRightPanel.header1') }}:
                <b id="d_id" class="patient-id">{{
                  subsRsrcId(rsrcResourceId)
                }}</b>
              </span>
            </p>
          </div>
          <div style="margin-right: 10px;float: right;">
            <label for="search_fhirResrc" class="sort-label"
              >· {{ $t('phr.fhir.bottomRightPanel.header2') }}</label
            >
            <select
              id="d_history"
              class="sort-input-select"
              v-model="rsrcSelectVersionId"
            >
              <option
                v-for="index in rsrcFixedVersionId"
                :key="index"
                :value="index"
              >
                {{ index }}</option
              >
            </select>
          </div>
        </div>
        <div class="panel-body" style="height:304px;overflow:hidden;">
          <div class="table-bot-btns2">
            <textarea
              :value="rsrcJsonData"
              id="d_json"
              name="d_json"
              style="width:100%; height:291px; overflow:auto; resize: none; border: solid; border-width: 1px; border-color: rgb(118, 118, 118);"
            ></textarea>
          </div>
        </div>
      </template>
    </four-panel>
  </section>
</template>

<script>
import FourPanel from '@/layouts/FourPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import mixins from '@/mixins/CommonMixins';
import {
  getPatientList,
  getPatientByHistory,
  getPatientRsrcList,
} from '@/api/phrApi';
import { FHIR_RESOURCES } from './resourceSearch.js';

export default {
  mixins: [mixins],
  components: {
    FourPanel,
    TableListComponent,
  },
  data: () => ({
    topRightOverlay: false,
    bottomRightOverlay: false,
    loading: false,
    RsrcLoading: false,
    fourPanel: {
      topLeftPanelWidth: '937',
      topRightPanelWidth: '450',
      bottomLeftPanelWidth: '937',
      bottomRightPanelWidth: '450',
    },
    topLeftTable: {
      topHeaders: [],
      // --- 환자 정보 검색 정보 --- //
      srvc: {
        fhirResrc: '',
        userId: '',
        userNm: '',
        pageId: '',
      },
      tableItems: [],
      totalRecords: 0,
      totalPages: 0,
      nowPerPage: 30,
      //   pageId: '',
    },
    bottomLeftTable: {
      bottomHeaders: [],
      rsrc: {
        resourceType: '',
        searchUrl: '',
        pageId: '',
      },
      tableItems: [],
      totalRecords: 0,
      totalPages: 0,
      nowPerPage: 30,
    },

    patientInfo: {
      patientDetailText: '',
      patientId: '',
    },
    // rowclick에서 선택한 Detail version
    fixedVersionId: 0,
    // select 노출 version
    selectVersionId: '',
    // rowclick에서 선택한 Detail version
    rsrcFixedVersionId: 0,
    // select 노출 version
    rsrcSelectVersionId: '',
    rsrcJsonData: '',
    rsrcResourceId: '',
    // 검색내용 편집
    patientRef: '',
    showSearchEditPopup: false,
  }),
  methods: {
    subsRsrcId(resourceId) {
      if (resourceId.length > 30) {
        return resourceId.substr(0, 30) + '...';
      }
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    async getPatientItemsList(searchParams) {
      this.loading = true;
      //   let params;
      const {
        rows,
        page,
        sord,
        // pageId,
        sidx,
        fhirResrc,
        userId,
        userNm,
      } = searchParams;
      const params = {
        rows,
        page,
        sidx,
        pageId: this.topLeftTable.srvc.pageId,
        sord,
        fhirResrc,
        userId,
        userNm,
      };
      await getPatientList(params)
        .then(res => {
          const { rows, totalRecords, totalPages, pageId } = res.data;
          this.topLeftTable.tableItems = rows;
          this.topLeftTable.totalRecords = totalRecords;
          this.topLeftTable.totalPages = totalPages;
          this.topLeftTable.srvc.pageId = pageId;
          this.loading = false;
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    async getRsrcList(searchParams) {
      if (!this.patientInfo.patientId) {
        return;
      }
      if (!searchParams.resourceType) {
        return;
      }

      this.RsrcLoading = true;
      const {
        rows,
        page,
        sord,
        pageId,
        sidx,
        resourceType,
        searchUrl,
      } = searchParams;
      const params = {
        rows,
        page,
        pageId,
        sidx,
        sord,
        resourceType,
        searchUrl,
      };

      await getPatientRsrcList(params)
        .then(res => {
          // const { rows, totalRecords, totalPages, pageId } = res.data;

          const {
            rows,
            totalRecords,
            totalPages,
            pageId,
            reqRows,
            page,
          } = res.data;
          if (params.searchUrl.includes('count') && totalRecords > reqRows) {
            this.bottomLeftTable.tableItems = rows.slice(
              (page - 1) * reqRows,
              reqRows * page,
            );
          } else {
            this.bottomLeftTable.tableItems = rows;
          }

          // this.bottomLeftTable.tableItems = rows;
          this.bottomLeftTable.totalRecords = totalRecords;
          this.bottomLeftTable.totalPages = totalPages;
          this.bottomLeftTable.rsrc.pageId = pageId;
          this.RsrcLoading = false;
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    async getPatientDetail(params) {
      this.topRightOverlay = true;
      await getPatientByHistory(params).then(res => {
        const { jsonData, versionId } = res.data;
        this.patientInfo.patientDetailText = jsonData;
        this.selectVersionId = parseInt(versionId);
        this.topRightOverlay = false;
      });
    },
    async getRsrcDetail(params) {
      this.bottomRightOverlay = true;
      await getPatientByHistory(params).then(res => {
        const { jsonData, versionId } = res.data;
        this.rsrcJsonData = jsonData;
        this.rsrcSelectVersionId = parseInt(versionId);
        this.bottomRightOverlay = false;
      });
    },
    /**
     * 리소스 타입 변경 시, searchUrl default setting
     */
    changeResrceType(e) {
      const value = e.target.value;
      const FHIR_RESOURCES = this.fhir_resources;
      const patientId = this.patientInfo.patientId;
      if (value && patientId) {
        for (let i in FHIR_RESOURCES) {
          if (FHIR_RESOURCES[i].value == value) {
            this.patientRef = FHIR_RESOURCES[i].patient;
            this.bottomLeftTable.rsrc.searchUrl = `?${this.patientRef}=${patientId}`;
          }
        }
      } else {
        this.bottomLeftTable.rsrc.searchUrl = '';
      }
    },
    topLeftRowClick(item) {
      this.patientInfo.patientId = item.fhirResrc;
      this.fixedVersionId = parseInt(item.versionId);
      const param = {
        historyId: this.fixedVersionId,
        pageId: '',
        patientId: this.patientInfo.patientId,
      };
      this.getPatientDetail(param);
      this.clearResrceDetail();
    },
    bottomLeftTableRowClick(item) {
      this.rsrcJsonData = item.jsonData;
      this.rsrcFixedVersionId = parseInt(item.historyId);
      this.rsrcSelectVersionId = parseInt(item.historyId);
      this.rsrcResourceId = item.resourceId;
    },
    /**
     * 검색 내용 편집 팝업 오픈
     */
    openSearchEditPopup() {
      // patientId 확인
      if (this.patientInfo.patientId == '') {
        const target = this.getPostWord(
          this.$i18n.t('phr.fhir.target-patient'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }
      // 리소스 타입 확인
      if (this.bottomLeftTable.rsrc.resourceType == '') {
        const target = this.getPostWord(
          this.$i18n.t('phr.fhir.target-resource'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }
      if (
        this.bottomLeftTable.rsrc.resourceType &&
        this.bottomLeftTable.rsrc.resourceType !== ''
      ) {
        this.$dialogs.editParam({
          fhirResrcType: this.bottomLeftTable.rsrc.resourceType,
          fhirCont: this.bottomLeftTable.rsrc.searchUrl,
        });
        this.$store.commit(
          'phr/setFhirCont',
          this.bottomLeftTable.rsrc.searchUrl,
        );
        return;
      }
    },
    /**
     * 리소스 상세 조회 팝업 닫기 및 clear
     */
    clearResrceDetail() {
      this.bottomLeftTable.rsrc.searchUrl = '';
      this.bottomLeftTable.rsrc.resourceType = '';
      this.bottomLeftTable.tableItems = [];
      this.rsrcJsonData = '';
      this.rsrcFixedVersionId = '';
      this.rsrcResourceId = '';
    },
  },
  computed: {
    fhirCont: {
      get() {
        return this.$store.getters['phr/getFhirCont'];
      },
      set(value) {
        this.$store.commit('phr/setFhirCont', value);
      },
    },
    /**
     * 리소스 타입
     */
    fhir_resources() {
      let fhir_resources_patient_ref = [];
      for (var i in FHIR_RESOURCES) {
        if (FHIR_RESOURCES[i].patient) {
          fhir_resources_patient_ref.push(FHIR_RESOURCES[i]);
        }
      }
      return fhir_resources_patient_ref;
    },
    itemsWithIndex() {
      return this.topLeftTable.tableItems.map((tableItems, index) => ({
        ...tableItems,
        userId:
          this.topLeftTable.tableItems[index].userId == null
            ? '-'
            : this.topLeftTable.tableItems[index].userId,
        userNm:
          this.topLeftTable.tableItems[index].userNm == null
            ? '-'
            : this.topLeftTable.tableItems[index].userNm,
      }));
    },
    topLeftPanelTitle() {
      return this.$i18n.t('phr.fhir.topLeftPanel.title');
    },
    topRightPanelTitle() {
      return this.$i18n.t('phr.fhir.topRightPanel.title');
    },
    bottomLeftPanelTitle() {
      return this.$i18n.t('phr.fhir.bottomLeftPanel.title');
    },
    bottomRightPanelTitle() {
      return this.$i18n.t('phr.fhir.bottomRightPanel.title');
    },
    topLeftTableHeaders() {
      return [
        {
          text: this.$i18n.t('phr.fhir.topLeftPanel.table-column1'),
          value: 'fhirResrc',
          width: '25%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.fhir.topLeftPanel.table-column2'),
          value: 'userId',
          width: '25%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.fhir.topLeftPanel.table-column3'),
          value: 'userNm',
          width: '25%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.fhir.topLeftPanel.table-column4'),
          value: 'versionId',
          width: '20%',
          sortable: false,
        },
      ];
    },
    bottomLeftTableHeaders() {
      return [
        {
          text: this.$i18n.t('phr.fhir.bottomLeftPanel.table-column1'),
          value: 'resourceType',
          width: '25%',
          sortable: false,
        },
        {
          text: this.$i18n.t('phr.fhir.bottomLeftPanel.table-column2'),
          value: 'fullUrl',
          width: '70%',
          sortable: false,
        },
      ];
    },
    btnEditOptions() {
      return this.$i18n.t('common.btn.update-option');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  created() {
    this.$store.commit('phr/setFhirType', 'fhir');
    this.topLeftTable.topHeaders = this.topLeftTableHeaders;
    this.bottomLeftTable.bottomHeaders = this.bottomLeftTableHeaders;
  },
  watch: {
    fhirCont() {
      this.bottomLeftTable.rsrc.searchUrl = this.fhirCont;
    },
    selectVersionId() {
      const param = {
        historyId: this.selectVersionId,
        pageId: '',
        patientId: this.patientInfo.patientId,
      };
      this.getPatientDetail(param);
    },
    rsrcSelectVersionId() {
      const param = {
        historyId: this.rsrcSelectVersionId,
        patientId: this.rsrcResourceId,
      };
      this.getRsrcDetail(param);
    },
    lang() {
      this.topLeftTable.topHeaders = this.topLeftTableHeaders;
      this.bottomLeftTable.bottomHeaders = this.bottomLeftTableHeaders;
    },
  },
  destroyed() {
    this.$store.commit('phr/setFhirCont', '');
  },
};
</script>

<style lang="scss" scoped>
.table-bot-btns2 {
  padding: 4px 7px;
  border-bottom: 0px solid #ccc;
}
.btnAddRow {
  min-width: 100%;
  border-radius: 5px;
}

tbody .sort-input-select {
  width: 100%;
}
</style>
