<template>
  <section>
    <two-panel
      :leftPanelWidth="twoPanel.leftPanelWidth"
      :rightPanelWidth="twoPanel.rightPanelWidth"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <table-list-component
          :tableHeight="'567'"
          ref="tableListComponent"
          :headers="headers"
          :items="tableItems"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="rsrc"
          :loading="loading"
          @getDataFromApi="getRsrcList"
          @rowClick="rowClick"
        >
          <template #searchOptions>
            <label for="search_fhirResrcType" class="sort-label">
              · {{ $t('phr.resource.search-condition1') }}</label
            >
            <select
              v-model="rsrc.fhirResrc"
              class="sort-input-select"
              style="width:194px;"
              id="search_fhirResrcType"
            >
              <option value="">{{
                $t('common.select', { target: '리소스' })
              }}</option>
              <option
                v-for="(ResrcType, key) in ResrcTypes"
                :key="key"
                :value="ResrcType.value"
                >{{ ResrcType.text }}</option
              >
            </select>
            <label for="search_resourceId" class="sort-label"
              >· {{ $t('phr.resource.search-condition2') }}</label
            ><input
              v-model="rsrc.resourceId"
              class="sort-input-text"
              id="search_resourceId"
              maxlength="20"
              @keyup.enter="doSearch"
              @keydown="inputLiteralCheck"
            />
            <br />
            <label
              for="search_fhirCont"
              class="sort-label"
              style="margin-left: 87px; margin-right: 14.5px;"
              >· {{ $t('phr.resource.search-condition3') }}</label
            ><input
              type="text"
              v-model="fhirCont"
              class="sort-input-text"
              id="search_fhirCont"
              style="width: 436px"
              @keyup.enter="doSearch"
            />
            <input
              type="button"
              id="contentButton_fhir"
              :value="btnEditOptions"
              class="sort-btn-search"
              style="width: 95px; color: #ffffff;"
              @click="editParam"
            />
          </template>
        </table-list-component>
      </template>
      <template #rightPanel>
        <div class="panel">
          <div id="detailPanel" class="panel">
            <div class="panel-top-right">
              <div>
                <p class="table-total">
                  <span class="tx-total"
                    >{{ $t('phr.resource.rightPanel.header1') }}:
                    <b id="d_id" class="patient-id">{{
                      rightPanel.resourceId
                    }}</b>
                  </span>
                </p>
              </div>
              <div style="margin-right: 10px;float: right;">
                <label for="search_fhirResrc" class="sort-label"
                  >· {{ $t('phr.resource.rightPanel.header2') }}</label
                >
                <select
                  id="d_history"
                  class="sort-input-select"
                  v-model="rsrcSelectVersionId"
                >
                  <option
                    v-for="index in rsrcFixedVersionId"
                    :key="index"
                    :value="index"
                  >
                    {{ index }}
                  </option>
                </select>
              </div>
            </div>
            <div class="panel-body" style="height:676px">
              <div class="table-bot-btns2">
                <textarea
                  :value="rightPanel.d_json"
                  id="d_json"
                  name="d_json"
                  style="width:100%; height:660px; overflow:auto; resize: none; border: solid; border-width: 1px; border-color: rgb(118, 118, 118);"
                  disabled
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </template>
    </two-panel>
  </section>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import mixins from '@/mixins/CommonMixins';
import { FHIR_RESOURCES } from './resourceSearch.js';
import { getResourceList, getDetailByHistory } from '@/api/phrApi';
export default {
  name: 'ResourcePage',
  mixins: [mixins],
  components: {
    TwoPanel,
    TableListComponent,
  },
  data: () => ({
    // panel width 값
    twoPanel: {
      leftPanelWidth: '820',
      rightPanelWidth: '567',
    },
    totalRecords: 0,
    totalPages: 0,
    tableItems: [],
    nowPerPage: 30,
    rsrc: {
      fhirResrc: '',
      resourceId: '',
      pageId: '',
      searchUrl: '',
    },
    rsrcDetail: {
      resourceId: '',
      versionId: '',
    },
    rightPanel: {
      resourceId: '',
      versionId: '',
      d_json: '',
    },
    rsrcSelectVersionId: '',
    rsrcFixedVersionId: '',
    ResrcTypes: FHIR_RESOURCES,
    loading: false,
    pageTemp: false,
  }),
  methods: {
    editParam() {
      if (this.rsrc.fhirResrc && this.rsrc.fhirResrc !== '') {
        this.$dialogs.editParam({
          fhirResrcType: this.rsrc.fhirResrc,
          fhirCont: this.fhirCont,
        });
        return;
      }
      const target = this.getPostWord(
        this.$i18n.t('phr.resource.target-resource'),
        '을',
        '를',
      );
      this.$dialogs.alert(
        this.$i18n.t('common.alert.target-select', { target }),
      );
    },
    doSearch() {
      this.$refs.tableListComponent.search();
    },
    async getRsrcList(searchParams) {
      if (this.pageTemp === false) {
        this.pageTemp = true;
        return;
      }
      if (this.rsrc.fhirResrc === '') {
        const target = this.getPostWord(
          this.$i18n.t('phr.resource.target-resource'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }
      this.loading = true;
      const {
        rows,
        page,
        sord,
        // pageId,
        sidx,
        fhirResrc,
        // searchUrl,
        resourceId,
      } = searchParams;
      const params = {
        rows,
        page,
        pageId: this.rsrc.pageId,
        sidx,
        sord,
        fhirResrc,
        searchUrl:
          resourceId.length > 0
            ? `?_id=${resourceId}&${this.fhirCont.slice(1)}`
            : this.fhirCont,
        resourceId,
      };
      await getResourceList(params)
        .then(res => {
          const {
            rows,
            totalRecords,
            totalPages,
            pageId,
            reqRows,
            page,
          } = res.data;
          if (params.searchUrl.includes('count') && totalRecords > reqRows) {
            this.tableItems = rows.slice((page - 1) * reqRows, reqRows * page);
          } else {
            this.tableItems = rows;
          }
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
          this.rsrc.pageId = pageId;
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rowClick(item) {
      this.rsrcDetail.resourceId = item.resourceId;
      this.rsrcDetail.versionId = item.versionId;
      const param = {
        patientId: this.rsrcDetail.resourceId,
        historyId: this.rsrcDetail.versionId,
      };
      this.getResourceDetail(param);
    },
    async getResourceDetail(param) {
      await getDetailByHistory(param).then(res => {
        this.rightPanel.d_json = res.data.jsonData;
        this.rightPanel.resourceId = this.substrRsrcId(res.data.patientId);
        this.rsrcFixedVersionId = parseInt(res.data.versionId);
        this.rsrcSelectVersionId = parseInt(res.data.versionId);
      });
    },
    async getSelectedResource(param) {
      await getDetailByHistory(param).then(res => {
        this.rightPanel.d_json = res.data.jsonData;
        this.rightPanel.resourceId = this.substrRsrcId(res.data.patientId);
        this.rsrcSelectVersionId = parseInt(res.data.versionId);
      });
    },
    substrRsrcId(RsrcId) {
      if (RsrcId.length > 30) {
        return RsrcId.substr(0, 30) + '...';
      }
      return RsrcId;
    },
  },
  computed: {
    fhirCont: {
      get() {
        return this.$store.getters['phr/getFhirCont'];
      },
      set(value) {
        this.$store.commit('phr/setFhirCont', value);
      },
    },
    leftPanelTitle() {
      return this.$i18n.t('phr.resource.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('phr.resource.leftPanel.table-column1'),
          sortable: false,
          value: 'fhirResrc',
          width: '30%',
        },
        {
          text: this.$i18n.t('phr.resource.leftPanel.table-column2'),
          sortable: false,
          value: 'resourceId',
          width: '45%',
        },
        {
          text: this.$i18n.t('phr.resource.leftPanel.table-column3'),
          sortable: false,
          value: 'versionId',
          width: '10%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('phr.resource.rightPanel.title');
    },
    btnEditOptions() {
      return this.$i18n.t('common.btn.update-option');
    },
  },
  created() {
    this.$store.commit('phr/setFhirType', 'resource');
  },
  watch: {
    rsrcSelectVersionId() {
      const param = {
        patientId: this.rsrcDetail.resourceId,
        historyId: this.rsrcSelectVersionId,
      };
      this.getSelectedResource(param);
    },
  },
  destroyed() {
    this.$store.commit('phr/setFhirCont', '');
  },
};
</script>

<style scoped lang="scss">
@charset "UTF-8";
.table-bot-btns2 {
  padding: 4px 7px;
  border-bottom: 0px solid #ccc;
}

tbody .sort-input-select {
  width: 100%;
}
::v-deep {
  .panel-top-sort {
    min-height: 70px !important;
  }
  .btnAddRow {
    min-width: 100%;
    border-radius: 5px;
  }
}
.panel-top-right {
  background: #e3e7ef;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 34px;
  position: relative;
}
#d_json {
  background-color: #fff !important;
  color: black !important;
}
</style>
